<template>
  <div class="col-md-4 panel-formulario">
    <div class="row contenedor-row">
      <div class="col-md-12">
        <div class="imagen-logo"></div>
      </div>

      <div class="col-md-12">
        <h1 class="bienvenido">Bienvenido</h1>
        <p>Inicia sesión en tu cuenta y has seguimiento de tus procesos como proveedor</p>
      </div>

      <div class="col-md-12 margen-superior">
        <div class="container-input-usuario">
          <input
            type="text"
            id="usuario"
            name="usuario"
            placeholder="Usuario"
            v-model="elementos.usuario"
          />
          <span><i class="bx bx-user"></i></span>
        </div>
      </div>

      <div class="col-md-12">
        <div class="container-input-usuario">
          <input
            type="password"
            id="clave"
            name="clave"
            placeholder="Contraseña"
            v-model="elementos.clave"
          />
          <span><i class="bx bx-lock"></i></span>
        </div>
      </div>

      <div class="margen-superior">
        <n-space justify="space-between">
          <it-button
            @click.prevent="iniciaSesion()"
            type="primary"
            class="btn-primario"
            icon="login"
            round
            >Ingresar</it-button
          >
          <small class="recordar-clave"><a>¿Recordar contraseña?</a></small>
        </n-space>
      </div>

      <div class="margen-superior">
        <n-space justify="center">
          <p>
            ¿No tienes una cuenta?,
            <router-link :to="{ name: 'registrar' }">Regístrate</router-link>
          </p>
        </n-space>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import useAuth from "../../composable/useAuth";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "Login",
  setup() {
    const router = useRouter();

    const { loggin } = useAuth();

    const elementos = ref({
      usuario: "",
      clave: "",
    });

    const iniciaSesion = async () => {
      let loading = document.getElementById("cp-loading");
      if (loading != null && loading != undefined) {
        loading.classList.remove("ocultar");
      }

      if (elementos.value.usuario == "") {
        alert("debe ingresar el usuario");
        loading.classList.add("ocultar");
      } else if (elementos.value.clave == "") {
        alert("Debe ingresar la clave para iniciar");
        loading.classList.add("ocultar");
      } else {
        const credenciales = JSON.stringify({
          usuario: elementos.value.usuario,
          password: btoa(elementos.value.clave),
          app: "ServiceOne",
          version_app: "4.0.8",
        });

        console.log(credenciales);

        const respuesta = await loggin(credenciales);
        console.log("Respuesta desde la vista de loging: " + respuesta.tipo);

        if (respuesta.tipo) {
          //location.reload();
          //router.push({ path: "/" });
          router.go("/");
        } else {
          loading.classList.add("ocultar");
          Swal.fire({
            title: "Atención",
            text: respuesta.mensaje,
            icon: "warning",
          });
        }

        /*const pruebas = JSON.stringify({
                    title: 'foo',
                    body: 'bar',
                    userId: 1,
                })
                
                const respuesta  = await axxios.post('http://portalrestrepogomez.com:61080/ApiCoreOnePruebas/RG/v1/Login', credenciales ,{
                    headers: { 'Content-Type': 'application/json' }              
                })
                console.log(respuesta); */
      }
    };

    onMounted(() => {
      const acceso = localStorage.getItem("idToken");
      if (acceso != null && acceso != "") {
        router.push({ path: "/" });
      }
    });

    return {
      elementos,
      iniciaSesion,
    };
  },
  components: {},
};
</script>

<style scoped>
* {
  /* padding : 0;
        margin: 0;
        height: 100%; */
}
.full-heigth {
  height: 100%;
}
.contenedor-row {
  width: 80%;
}
.contenedor-principal {
  width: 100%;
  height: 100vh;
  background-color: var(--ng-background);
  display: flex;
  justify-content: center;
}
.contenedor-secundario {
  width: 70% !important;
}

.panel-formulario {
  display: flex;
  align-items: center;
  justify-content: center;
  /*padding: 0 2rem 0 2rem;*/
}

.imagen-logo {
  width: 100%;
  height: 7rem;
  background-image: url(../../assets/images/img_logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.imagen-login {
  width: 80%;
  height: 80%;
  background-image: url(../../assets/images/ic_image_login.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.bienvenido {
  text-align: left;
  font-weight: bold;
  font-size: 1.6em;
  margin-top: 1.6em;
}

p {
  font-size: 0.9em;
  text-align: justify;
}

.margen-superior {
  margin-top: 2rem;
}

.container-input-usuario {
  width: 100%;
  height: 2.6rem;
  background: var(--ng-blanco);
  border-radius: 50px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  outline: none;
  list-style: none;
  margin-bottom: 1rem;
  box-shadow: 12px 11px 21px -19px rgb(0, 0, 0, 1);
}

.container-input-usuario > input {
  border: 1px solid transparent;
  color: rgba(var(--vs-text), 1);
  border-radius: inherit;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  padding: 7px 13px 7px 10px;
  width: 100%;
  padding-left: 46px;
  outline: none;
  list-style: none;
  background: var(--ng-blanco);
  font-size: 0.86em;
}

.container-input-usuario > span {
  position: absolute;
  right: auto;
  width: 2.9em;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 12px 0 10px -10px rgba(0, 0, 0, var(--vs-shadow-opacity));
  box-shadow: 12px 0 10px -10px rgba(0, 0, 0, var(--vs-shadow-opacity));
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  border-radius: inherit;
  background: rgba(var(--vs-gray-2), 1);
  pointer-events: none;
  left: 0;
  outline: none;
  list-style: none;
  transition: all 0.2s ease-out;
}

.container-input-usuario > span > i {
  font-size: 1.2em;
}
.recordar-clave {
  font-size: 0.79em;
  cursor: pointer;
}

.input-content-ng1 {
  width: 100%;
  height: 2.6rem;
  background: #f4f7f8;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  outline: none;
  list-style: none;
  margin-bottom: 1rem;
}

.ng-input1 {
  border: 1px solid transparent;
  color: rgba(var(--vs-text), 1);
  border-radius: inherit;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  padding: 7px 13px 7px 10px;
  padding-left: 10px;
  width: 100%;
  padding-left: 38px;
  outline: none;
  list-style: none;
  background: #f4f7f8;
}

.ng-input__icon {
  position: absolute;
  right: auto;
  width: 36px;
  height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 12px 0 10px -10px rgba(0, 0, 0, var(--vs-shadow-opacity));
  box-shadow: 12px 0 10px -10px rgba(0, 0, 0, var(--vs-shadow-opacity));
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  border-radius: inherit;
  background: rgba(var(--vs-gray-2), 1);
  pointer-events: none;
  left: 0;
  outline: none;
  list-style: none;

  transition: all 0.2s ease-out;
}
.ng-input1:focus ~ .ng-input__icon {
  cursor: pointer;
  transform: translateY(-3px) translateX(-6px) translateZ(14px);
  box-shadow: 12px 6px 10px -11px rgba(0, 0, 0, 0.2);
  /*box-shadow: 0 1px 7px rgba(255,255,255,0.1);
        background: rgba(37,100,255,.15);*/
  background: #f7fafb;
}

.ng-input1:focus {
  border-bottom: 2px solid #0d6efd69;
}

.ng-input1:focus ~ .ng-input__icon > i {
  color: #0d6efd;
}

.formulario-login {
  margin-top: 3rem;
}
</style>
